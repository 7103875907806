/** @jsx jsx */
import { jsx } from "theme-ui"
import { graphql, Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import ProjectBlurb from "../components/project-blurb"
import { Separator } from "../components/gatsby-sidebar/docs-breadcrumb"
import SiteLinks from "../components/site-links"
import CopyrightInfo from "../components/copyright-info"

// https://xstate.js.org/viz/?gist=894bad8afd658a036cfbb9b78201e103
// https://codesandbox.io/s/relaxed-https-tnh4q?file=/src/calculator2.js

const IndexProjectPage = ({ data }) => {
  //console.log(projects)
  //return null
  const projectList = data.allFile.edges
  
  return (
    <Layout>
      <SEO title="Projects" />
      <div
        sx={{
          m: `1.45rem auto`,
          maxWidth: 900,
        }}
      >
        <h2>Projects</h2>

        {projectList.map(({ node }) => (
          <div key={node.id}>
            <ProjectBlurb data={node} />
          </div>
        ))}
      </div>

      <SiteLinks>
        <Link to="/">Landing Page</Link>
        <Separator character={<span>{` `}</span>} />
        <Link to="/index-blog/">Blog</Link>
      </SiteLinks>

      <CopyrightInfo />
    </Layout>
  )
}

export default IndexProjectPage

export const projects = graphql`{
  allFile(filter: {sourceInstanceName: {eq: "projects"}, ext: {eq: ".md"}}) {
    edges {
      node {
        id
        childMarkdownRemark {
          frontmatter {
            title
            date(formatString: "DD MMMM YYYY")
          }
          fields {
            slug
            sourceName
          }
          excerpt(pruneLength: 100)
          html
        }
      }
    }
  }
}`
