/** @jsx jsx */
import { jsx } from "theme-ui"
import { Link } from "gatsby"
import { useState } from "react"

export default ({data}) => {
//  const {slug, title, date, commentary, excerpt} = data
  const [isOpen, setIsOpen] = useState(false)

  return (
    <div>
      <div sx={{ width: `900px` }}>
        <Link
          to={data.childMarkdownRemark.fields.slug}
          sx={{
            color: `inherit`,
            textDecoration: `none`,
          }}
        >
          <div
            sx={{
              fontWeight: 500,
              fontSize: `1.14471rem`
            }}
          >
            {data.childMarkdownRemark.frontmatter.title}
            {` `}
            <span 
              sx={{
                color: `#bbb `,
                fontSize: `16px`
              }}
            >
              - {data.childMarkdownRemark.frontmatter.date}
            </span>                
          </div>

        </Link>
      </div>
      <div
        sx={{
  
          mb: `20px`,
          pr: `100px`,
          position: `relative`,
          textDecoration: `none`,
          width: `900px`,
          zIndex: 1
        }}
      >
        { isOpen ? <div dangerouslySetInnerHTML={{ __html: data.childMarkdownRemark.html}} /> : data.childMarkdownRemark.excerpt }
        <button
          type='button'
          sx={{
            background: `transparent`,
            border: `white solid 1px`,
            fontSize: `14px`,
            fontWeight: `normal`,
            height: `20px`,
            padding: 0,
            position: `absolute`,
            bottom: 0,
            right: `15px`,
            zIndex: 5,
            ":hover": {
              border: `white solid 1px`,
              cursor: 'pointer'
            },
            ":focus": {
              border: `white solid 1px`
            }
          }}
          onClick={() => setIsOpen(isOpen => !isOpen )}
        >
          show {isOpen ? "less" : "more"}...
        </button>
      </div>
    </div>
  )
}